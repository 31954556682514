.receive--chat {
    margin: 10px 35px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px;
    width: max-content;
    height: max-content;
}

.user--chat {
    margin-top: 10px;
    padding: 15px;
    border-radius: 12px;
    width: max-content;
    height: max-content;
    /* float: right; */
    max-width: 80vw;
    margin-right: 0px !important;
    background-color: #e3effd !important;
    margin-left: auto;
}